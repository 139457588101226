details {
    margin-bottom: 5px;
    summary {
        color: white;
        text-align: left;
        padding: 10px 20px;
        cursor: pointer;
        outline:none;
        list-style: none;
        font-weight: 300;
        font-size: 16px;
        background: #517cad;

        display: flex;
        flex-direction: row;
        align-items: center;

        .summary-title {
            width: 50%;
        }

        .summary-subtitle {
            width: 50%;
            a {
                color: white;
            }
        }
    }  
}