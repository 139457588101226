.title-bar-container {
    display:flex;
    flex-direction: column;
    padding: 10px 30px;
    
    justify-content: space-between;
    font-family: 'Roboto';
    .titles {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color:rgb(80,89,100);
        text-align:left;
        border-bottom: 1px solid rgb(80,89,100);
        .main-title{
            font-size:20px;
            margin-bottom: 3px;
        }
        .main-subtitle {
            font-size:15px;
            color:rgb(80,89,100);
            margin-bottom: 3px;

        }

        .project-icon{
            cursor: pointer;
            width: 20px;
            margin-bottom: 3px;
        }
        
        margin-bottom: 2px;

    }
    .title-bar-icons {
        color:rgb(80,89,100);;
        display:flex;
        align-items:center;
    }
}