.sidePanel {
    width: 400px;
    height:60%;
    &.emptyWithIn{
        height:90%;
    }
    background: rgb(220,221,223);
}

.nearby {
    width: 400px;
    height: 39%;
    &.empty{
        height:9%;
    }
    background: rgb(220,221,223);
    .radius{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px 15px;
        .radius-button{
            cursor: pointer;
        }
        #outer-circle {
            width:50px;
            height:50px;
            border-radius:50%;
            background-color:#CE1126;
            background-clip:content-box;
            padding:10px;
            border:10px solid #00247D;
          }
    }
}

.menu-panel {
    width: 215px;
    background: #517cad;
    height:100%;

    .menu-group{
        border-bottom: 1px solid white;
    }
}

.title-container {
    background-color: rgb(0, 0, 0);
    text-align: center;

    .tag-title {
        padding-top: 15px;
        padding-bottom: 15px;
        margin: 0px;
        color: #FFCE2F;
        font-weight: 400;
        font-size: 1em;
        font-family: 'Roboto', sans-serif;
        margin: 0px 0px 0px 0px;
    }
}

.overview-thumbnail {
    font-size: 0px;

    img {
        clip: rect(0px, 0px, 50px, 0px);
        margin-left: 0px;
        padding-left: 0px;
        height: 200px;
        width: 100%;
    }
}

.overview-container {
    width: 100%;

    display: flex;
    justify-content: space-between;
    padding: 6px 0px;

    .overview-heading {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 12px;
        color: #fff;
        margin-left: 20px;
    }

    .dropdown-icon {
        margin-right: 15px;
        display: flex;
        align-items: center;

        i {
            color: #fff;
            font-size: 14px;
        }
    }
}

.activity-steps-box {
    padding-left: 25px;
    position: absolute;
    top: 5%;
    z-index: 1;
    left: 35%;
    width: 60%;
    background: rgba(65, 68, 80, 0.318);
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow-y: scroll;
    height: 85%;

    .title-bar {
        display: flex;
        justify-content: space-between;
        padding-right: 25px;
        padding-top: 10px;
        color: #A9A9AA;

        .title {
            padding-top: 15px;
            padding-bottom: 5px;
            margin-bottom: 0;
            color: white;
            font-size: 11px;
            text-transform: uppercase;
        }
    }

    .narrative {
        color: white;
        padding: 20px;
    }
}

@import "../../../node_modules/react-toggle-switch/dist/css/switch.min.css";

.layer-title {
    color: #cccccc;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.bathymetry-title {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.panel-title {
    color: white;
    font-weight: 400;
    font-size: 12px;
    padding: 10px 24px;
    text-align: center;
    background: #2f484859;
    border-top: 1px solid #666973;
    border-bottom: 1px solid #666973;
}

.switch {
    width: 37px;
    height: 20px;
    margin-right: 10px;

    &.on {
        .switch-toggle {
            left: 15px;
        }
        background: #8a8c92;
    }

    .switch-toggle {
        width: 20px;
        height: 24px;
    }
}

.layer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 32px;
    border-bottom: 1px solid;
    border-image: linear-gradient(90deg, rgba(59, 64, 82, 0) 0%, rgb(255, 255, 255) 33%, rgb(255, 255, 255) 66%, rgba(59, 64, 82, 0) 100%) 1;

    .layer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        font-size: 14px;
        font-weight: 200;
        color: #ccc;

    }
}

.sketchfab-viewer {
    padding-top: 20px;
}

.menu-container {
    display: flex;

    .menu-item {
        width: 100%;
    }
}

.installation-image {
    align-self: center;
    margin: 5px;
    width:100%;
    height:100%;
}